import React, { useEffect } from 'react'
import granim from 'granim'

export default function Loader() {
  useEffect(() => {
    new granim({
      element: '#canvas',
      direction: 'diagonal',
      isPausedWhenNotInView: true,
      states : {
        'default-state': {
          gradients: [
            [
              { color: '#EC4700', pos: 0 },
              { color: '#F5762F', pos: .2 },
              { color: '#FF9900', pos: .6 },
              { color: '#FAFF00', pos: 1 }
            ],
            [
              { color: '#EC4700', pos: -0.2 },
              { color: '#F5762F', pos: .3 },
              { color: '#FF9900', pos: .7 },
              { color: '#FAFF00', pos: 1.2 }
            ],
            [
              { color: '#EC4700', pos: 0 },
              { color: '#F5762F', pos: .1 },
              { color: '#FF9900', pos: .4 },
              { color: '#FAFF00', pos: 1 }
            ],
            [
              { color: '#EC4700', pos: -0.1 },
              { color: '#F5762F', pos: .3 },
              { color: '#FF9900', pos: .6 },
              { color: '#FFC700', pos: 1 }
            ],
          ]
        }
      }
    })
  }, [])
  //  style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1534142499731-a32a99935397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80")', backgroundSize: 'cover' }}
  return (
    <canvas id="canvas" className="fixed w-screen h-screen top-0 left-0 -z-10" />
  )
}

